import { useCallback, useRef } from 'react';
import useSWR, { Key, useSWRConfig } from 'swr';
import { BareFetcher, SWRConfiguration } from 'swr/_internal';
import { MutatorOptions } from 'swr/dist/types';

export const useCustomSWR = <Data>(
  url: Key,
  fetcher: BareFetcher<Data> | null,
  options: CustomSWRConfiguration<Data> = {}
): UseCustomSWRType<Data> => {
  const { cache, mutate } = useSWRConfig();
  const { initialData } = options;
  const storedData = useRef<Data | undefined>(initialData);

  if (initialData && typeof url === 'string' && !cache.get(url)) {
    mutate(url, initialData, { revalidate: false });
  }

  const { data, error, isValidating, mutate: selfMutate } = useSWR<Data>(
    url,
    fetcher,
    options
  );

  const mutateSelfAdaptor = useCallback(
    (
      data?: Data,
      opts?: boolean | MutatorOptions
    ): Promise<Data | undefined> => {
      // If revalidate is set, so remove from the reset the storedData
      const revalidate = typeof opts === 'boolean' ? opts : opts?.revalidate;

      if (revalidate) {
        storedData.current = data;
      }

      return selfMutate(data, opts);
    },
    [selfMutate, storedData]
  );

  const isLoading = !data && !error && isValidating;
  const isLoaded = (!!data || !!error) && !isValidating;

  if (isLoaded) {
    storedData.current = data;
  }

  return {
    data: storedData.current,
    error,
    isLoading,
    isLoaded,
    isValidating,
    mutate: mutateSelfAdaptor,
  };
};

type MutateCustomSWRType<Data> = (
  data?: Data,
  opts?: boolean | MutatorOptions
) => Promise<Data | undefined>;

type UseCustomSWRType<Data> = {
  data: Data | undefined;
  error: Error | undefined;
  isValidating: boolean | undefined;
  isLoading: boolean;
  isLoaded: boolean;
  mutate: MutateCustomSWRType<Data>;
};

type CustomSWRConfiguration<Data> = SWRConfiguration & { initialData?: Data };
