import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cartContext } from 'contexts/cartContext';
import { userContext } from 'contexts/userContext';
import { useCustomSWR } from 'hooks/useCustomSWR';
import { useCustomer } from 'hooks/useCustomer';
import useHistory from 'hooks/useHistory';
import usePrivilege from 'hooks/usePrivilege';
import { apiUrls } from 'services/api-urls';
import { fetcherWithBody } from 'services/fetcher';
import {
  deleteLocalAddToCart,
  updateLocalAddToCart,
} from 'services/gtm/gtmAddToCart';
import { ENV_API_URL_CHECKOUT } from 'settings/env';
import {
  Cart,
  CartOfferedGift,
  CartProduct,
  CartSpecialOffer,
} from 'types/Cart';
import { ProductDataDetail } from 'types/Product';

function CartProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { t } = useTranslation();
  const { retarg } = useContext(userContext);
  const { prevState } = useHistory();
  const { isLogged } = useCustomer();
  const {
    privilegeCode,
    setPrivilegeCode,
    validatePrivilegeCode,
    resetPrivilegeCode,
  } = usePrivilege();
  const [cartPrivilegeMessage, setCartPrivilegeMessage] = useState<string>('');

  const params = {
    privilegeCode,
    user: {
      retarg: retarg,
    },
  };

  const { data: cartData, mutate, error, isLoaded } = useCustomSWR<Cart>(
    undefined !== retarg && privilegeCode?.match(/([A-Z]|[0-9]){1,20}/)
      ? [apiUrls.getCart(), params]
      : null,
    fetcherWithBody
  );

  const cart = useMemo(() => {
    if (!cartData) {
      return cartData;
    }

    const columns: (keyof CartColumnsNbProducts)[] = [
      'products',
      'selectedOfferedGifts',
      'selectedSpecialOffers',
    ];

    const newCart = { ...cartData };
    newCart.nbProducts = columns.reduce((previousValue, cartDataKey) => {
      const items = cartData[cartDataKey] || [];
      let result = previousValue;

      result += (items as []).reduce((sum, item) => {
        if (cartDataKey === 'products') {
          return sum + +(item as CartProduct).quantity;
        } else {
          const isVisible =
            (item as CartNbProductsOfferType).attributes.isInvisible !== 'O';

          return sum + (isVisible ? 1 : 0);
        }
      }, 0);

      return result;
    }, 0);

    return newCart;
  }, [cartData]);

  useEffect(() => {
    function refreshCart() {
      mutate(cart, true);
    }

    document.addEventListener('refreshCart', refreshCart);
    return () => {
      document.removeEventListener('refreshCart', refreshCart);
    };
  }, []);

  useEffect(() => {
    if (cart) {
      const { newPrivilegeCode, currentPrivilegeCode } = cart;
      setPrivilegeCode(newPrivilegeCode ?? currentPrivilegeCode ?? '');
      newPrivilegeCode &&
        setCartPrivilegeMessage(t('LABEL_DEFAULT_PRIVILEGE_CODE_SET'));
    }
  }, [cart]);

  useEffect(() => {
    if (null === isLogged) {
      return;
    }

    void mutate(undefined, true); // Force to update cart
  }, [isLogged]);

  const handleSetPrivilegeCode = (newPrivilegeCode: string) => {
    setCartPrivilegeMessage('');
    setPrivilegeCode(newPrivilegeCode);
  };

  function mutateCart(newCart: Cart) {
    mutate(newCart || [], true);
  }

  function addToOctipasApiBasket(octipasCartContent: OctipasCartBodyRequest) {
    fetch(`${process.env.REACT_APP_URL_API_ORDER}/cart/add`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
      body: JSON.stringify(octipasCartContent),
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => console.log(data.message));
          return;
        }
        response.json();
      })
      .catch((error: Error) => console.log(error));
  }

  function updateOctipasApiBasket(
    octipasCartContent: OctipasCartBodyRequest,
    updatedCart: { cart: Cart }
  ) {
    fetch(`${process.env.REACT_APP_URL_API_ORDER}/cart/add`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
      body: JSON.stringify(octipasCartContent),
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => console.log(data.message));
          mutate(updatedCart?.cart || [], true);
          return;
        }
        response.json();
      })
      .catch((error: Error) => {
        mutate(updatedCart?.cart || [], true);
        console.log(error);
      });
  }

  async function addToMyBasket(
    cartContent: CartBodyRequest | CartBodyRequest[],
    type?: string,
    octipasCartContent?: OctipasCartBodyRequest,
    productData?: ProductDataDetail,
    openModal?: (modalId: string) => void,
    closeModal?: () => void,
    setErrorMessage?: (errorMessage: string) => void
  ) {
    return fetch(`${ENV_API_URL_CHECKOUT}/cart/add`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(cartContent),
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          if (typeof openModal === 'function') openModal('error');
          response.json().then(data => {
            if (typeof setErrorMessage === 'function')
              setErrorMessage(data?.message);
          });
          return;
        }

        return response.json().then(updatedCart => {
          if (typeof closeModal === 'function') closeModal();
          mutate(updatedCart || [], true);

          if (typeof openModal === 'function') openModal('addToMyBasket');
          if (octipasCartContent) {
            addToOctipasApiBasket(octipasCartContent);
          }

          updateLocalAddToCart(cartContent, type ?? '', prevState?.location);

          return updatedCart;
        });
      })
      .catch((error: Error) => {
        if (typeof openModal === 'function') openModal('error');
        if (typeof setErrorMessage === 'function')
          setErrorMessage(error.message);
      });
  }

  function updateMyBasket(
    cartContent: CartBodyRequest,
    octipasCartContent?: OctipasCartBodyRequest,
    openModal?: (modalId: string) => void,
    setErrorMessage?: (errorMessage: string) => void
  ) {
    return fetch(`${ENV_API_URL_CHECKOUT}/cart/update`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(cartContent),
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => {
            if (typeof setErrorMessage === 'function')
              setErrorMessage(data?.message);
            if (typeof openModal === 'function') openModal('error');
          });
          return;
        }

        return response.json().then(updatedCart => {
          mutate(updatedCart || [], true);
          if (octipasCartContent) {
            updateOctipasApiBasket(octipasCartContent, updatedCart);
          }

          return updatedCart;
        });
      })
      .catch((error: Error) => {
        if (typeof openModal === 'function') openModal('error');
        if (typeof setErrorMessage === 'function')
          setErrorMessage(error.message);
      });
  }

  function deleteProduct(
    sku: string,
    reference: string,
    size: string,
    type?: string,
    specialSku?: string
  ) {
    return fetch(`${ENV_API_URL_CHECKOUT}/cart/remove`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
      body: JSON.stringify({
        sku: sku,
        catalogSku: reference,
        type: type,
        specialSku: specialSku,
        sizeLabel: size,
      }),
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => console.log(data.message));
          return;
        }

        return response.json().then(updatedCart => {
          mutate(updatedCart || [], true);
          deleteLocalAddToCart(sku);

          return updatedCart;
        });
      })
      .catch((error: Error) => console.log(error));
  }

  function updateGiftChoice(giftChoice: string) {
    return fetch(`${ENV_API_URL_CHECKOUT}/cart/gift_choice/update`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
      body: JSON.stringify(giftChoice),
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => console.log(data.message));
          return;
        }
        response.json().then(updatedCart => {
          mutate(updatedCart || [], true);
        });
      })
      .catch((error: Error) => console.log(error));
  }

  return (
    <cartContext.Provider
      value={{
        cart,
        cartApiError: error,
        privilegeCode,
        setPrivilegeCode: handleSetPrivilegeCode,
        cartPrivilegeMessage,
        addToMyBasket,
        mutateCart,
        updateMyBasket,
        deleteProduct,
        updateGiftChoice,
        validatePrivilegeCode,
        isLoaded,
        resetPrivilegeCode,
      }}
    >
      {children}
    </cartContext.Provider>
  );
}

export default CartProvider;

type CartColumnsNbProducts = {
  products: Cart['products'];
  selectedOfferedGifts: Cart['selectedOfferedGifts'];
  selectedSpecialOffers: Cart['selectedSpecialOffers'];
};

type CartNbProductsOfferType = CartOfferedGift | CartSpecialOffer;

export type CartBodyRequest = {
  sku: string;
  catalogSku: string;
  qty: string;
  url?: string;
  sizeLabel?: string;
  cupLabel?: string;
  so?: string;
  gift?: string;
  line?: string;
  productData?: ProductDataDetail;
  price?: string;
  preDiscountPrice?: string;
  stock?: string;
};

export type CartProductsBodyRequest = {
  products: CartBodyRequest[];
};

export type OctipasCartBodyRequest = {
  BasketLines: {
    Quantity: string;
    ProductId: string | undefined;
    Price: string;
  }[];
};
